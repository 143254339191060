<!-- 有序充电 -->

<template>
  <div class="orderly-charg main-cnt">
    <div class="content">
      <common-table ref="chargeTable" tableHeight="calc(100vh - 325px)" :ischeck="false" :ispaging="true"
        :apiName="PowerStations.getOrderlyLists" :filters="filters" :columns="tableColumns" @onDetails="onDetails"
        @onAssociate="onAssociate" @onPowerStation="onPowerStation">
        <template #operate v-if="authData.indexOf('n_rHSXDGoEOPqR8W5znbwk3PEmwOtm') != -1">
          <el-button type="primary" @click="onAddBtn">新增规则</el-button>
        </template>

        <template #power_info="{ row }">
          <div v-if="row.oc_type == '2'">{{ row.oc_power }}kw</div>
          <div v-else class="flex" style="flex-wrap: wrap;">
            <div v-for="(item, i) in row.list" :key="i" class="squeezin">
              <div>
                <span v-if="item.ocl_etime">{{ item.ocl_stime }}-{{ item.ocl_etime }}，</span>
                <span>{{ item.ocl_power }}kw</span>
                <span v-if="i !== row.list.length - 1">；</span>
              </div>
            </div>
          </div>
        </template>
      </common-table>
    </div>

    <!-- 新增、编辑规则 -->
    <w-dialog ref="addDialog" class="add-dialog" :title="isAdd ? '新增规则' : '编辑规则'" width="65%" btnWidth="140px" top="3vh"
      :confirmText="isAdd ? '确认新增' : '确认保存'" @wConfirm="addConfirmBtn">
      <el-form class="add-form" ref="addForm" :model="ruleForm" :rules="rules" labelPosition="top">
        <el-row :gutter="40">
          <el-col :span="8">
            <el-form-item label="规则名称" prop="oc_name">
              <el-input v-model="ruleForm.oc_name" placeholder="请输入规则名称" class="input-white"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="限额方式" prop="oc_type">
              <el-select v-model="ruleForm.oc_type" placeholder="请选择周期" @change="wayCahnge" :disabled="!isAdd"
                class="input-white">
                <el-option label="分时限额" value="1"></el-option>
                <el-option label="固定限额" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8" v-if="ruleForm.oc_type == '2'">
            <el-form-item label="功率限额" prop="oc_power">
              <el-input v-model="ruleForm.oc_power" placeholder="请输入功率限额">
                <template #append>kw</template>
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24" v-if="ruleForm.oc_type == '1'">
            <el-form-item label="" prop="list">
              <template #label>
                <div class="flex align-center">
                  <div>
                    <span class="sign">*</span>
                    <span>分时功率限额设置</span>
                  </div>
                  <el-button type="primary" @click="onPowerLimit" class="add-btn">添加</el-button>
                </div>
              </template>
              <div class="power-limit">
                <div v-for="(item, i) in powerLimitList" :key="i" class="flex-btw s-m-b">
                  <div class="squeezin s-m-r">序号：{{ i + 1 }}</div>
                  <div class="flex">
                    <div class="squeezin">时&nbsp;&nbsp;&nbsp;&nbsp;段：</div>
                    <div class="flex"
                      style="background-color: #fff;border-radius: 4px;border: 1px solid #CDCFD3;width: 300px;">
                      <el-input v-model="item.ocl_stime" placeholder="请选择" @blur="onTimeBlur(i, 'start')"
                        class="input-sharing">
                      </el-input>
                      <div>至</div>
                      <el-input v-model="item.ocl_etime" placeholder="请选择" :suffix-icon="Clock"
                        @blur="onTimeBlur(i, 'end')" class="input-sharing">
                      </el-input>
                    </div>
                    <!-- <el-time-picker v-model="item.time" is-range format="HH:mm" value-format="HH:mm" range-separator="至"
                      start-placeholder="请选择" end-placeholder="请选择" :clearable="false"
                      style="width: 300px !important;" /> -->
                  </div>
                  <div class="flex">
                    <div class="flex">
                      <div class="squeezin">充电站功率限额：</div>
                      <el-input v-model="item.ocl_power" placeholder="请输入" class="input-white"
                        style="width: 300px !important;">
                        <template #append>kw</template>
                      </el-input>
                    </div>
                    <el-button type="text" @click="onDeleteBtn(i)" style="color: #F0170A;">删除</el-button>
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>

        <div v-if="isAdd">
          <w-title :title_name="'关联电站'"></w-title>
          <div class="flex s-m-b">
            <el-input v-model="searchStation" placeholder="请输入电站名称搜索" clearable :prefix-icon="Search"
              class="cell-30 input-white">
            </el-input>
            <el-button type="primary" @click="getUnboundStationList" class="search-station">搜索</el-button>
          </div>
          <el-table ref="tableref" :data="tableDatas" stripe style="width: 100%" element-loading-text="数据加载中"
            tooltip-effect="light" @selection-change="stationSelectionChange" height='380' :row-key="getStationKeys">
            <el-table-column type="selection" width="55" :reserve-selection="true" />
            <el-table-column prop="s_name" label="电站名称" show-overflow-tooltip />
            <el-table-column prop="s_no" label="电站编号" show-overflow-tooltip />
            <el-table-column prop="city_name" label="区域" show-overflow-tooltip />
            <el-table-column prop="equipment_count" label="充电桩总数" show-overflow-tooltip />
            <el-table-column prop="equipment_count_fast" label="快充数量" show-overflow-tooltip />
            <el-table-column prop="equipment_count_slow" label="慢充数量" show-overflow-tooltip />
            <el-table-column prop="s_busine_hours" label="营业时间" show-overflow-tooltip />
            <el-table-column prop="mi_name" label="上线明细" show-overflow-tooltip />
            <el-table-column prop="s_hk_park_code" label="关联停车场" show-overflow-tooltip />
          </el-table>
        </div>

        <div v-else>
          <w-title :title_name="'关联电站明细'"></w-title>
          <common-table ref="stationTable" tableHeight="250px" :ischeck="false" :ispaging="false"
            :tableData="stationTableDatas" :columns="stationColumns" @onUnbindBtn="onUnbindBtn">
          </common-table>
        </div>
      </el-form>
    </w-dialog>

    <!-- 详情 -->
    <OrderedRulesDetails ref="refInfo" @onUnbind="onUnbind" @submit="onSubmit"></OrderedRulesDetails>

    <!-- 关联电站 -->
    <AssociatedStation ref="refStation" @submit="onRefresh"></AssociatedStation>

    <!-- 电站详情 -->
    <PowerStationInfo ref="refStationInfo"></PowerStationInfo>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch, reactive, nextTick, } from "vue";
import { PowerStations } from "@/plugins/api.js";
import { ElMessage, ElMessageBox, } from "element-plus";
import { useStore } from "vuex";
import { Search, Clock, } from "@element-plus/icons-vue";
import OrderedRulesDetails from "../components/OrderedRulesDetails.vue";
import AssociatedStation from "../components/AssociatedStation.vue";
import PowerStationInfo from "../components/PowerStationInfo.vue";

const store = useStore();
const menuTokens = computed(() => store.state.menuToken.menuTokens);
const authData = ref([]);
watch(
  () => menuTokens.value,
  (data) => {
    if (data.length) {
      authData.value = data;
    }
  },
  {
    deep: true,
    immediate: true,
  }
);
/** 表格对象 */
const chargeTable = ref(null);
/** 筛选条件列表 */
const filters = ref([
  {
    name: "keywords",
    filterType: "search",
    value: "",
    placeholder: "请输入规则名称查询",
  },
]);
/** 表格配置数据 */
const tableColumns = ref([
  {
    prop: "oc_id",
    label: "序号",
  },
  {
    prop: "oc_name",
    label: "规则名称",
    color: "--text-color",
  },
  {
    type: "block",
    prop: "station_count",
    label: "关联电站",
    active: "onPowerStation",
    minWidth: 100,
    token: "n_aZPHXvkqBmvIoy34Z3090ZPOc582",
  },
  {
    prop: "oc_type_text",
    label: "功率限额方式",
  },
  {
    prop: "power_info",
    label: "详情",
    type: 'customRender',
    minWidth: 500,
  },
  {
    type: "operation",
    prop: "",
    label: "操作",
    minWidth: 200,
    bottons: [
      {
        name: "详情",
        action: "onDetails",
        token: "n_QgQ5Tv9lT6zF41tsKVg9XVVxCuiT",
      },
      {
        name: "关联电站",
        action: "onAssociate",
        token: "n_ILjXq3k2A42BsBQLfWYWgmyEMsmO",
      }
    ],
  },
]);
// 关联电站表格对象
const stationTable = ref(null);
const stationTableDatas = ref([]);
// 关联电站表格配置
const stationColumns = ref([
  {
    prop: "s_id",
    label: "序号",
    showTooltip: true,
  },
  {
    prop: "s_name",
    label: "电站名称",
    showTooltip: true,
  },
  {
    type: "operation",
    prop: "",
    label: "操作",
    minWidth: 100,
    bottons: [
      {
        name: "解绑",
        action: "onUnbindBtn",
        className: "error-font-btn",
        token: "n_u8XVqOTDC5wtn1hdMDdG5XBcB0k2",
      },
    ],
  },
]);

// 新增、编辑弹框对象
const addDialog = ref(null);
/** 是否新增 */
const isAdd = ref(true);
/** 表单对象 */
const addForm = ref(null);
/** 表单数据对象 */
const ruleForm = ref({
  oc_name: '',  // 规则名称
  oc_type: '1',  // 限额方式1分时限额2固定限额
  oc_power: '',  // 限额功率（只有限额方式为固定限额才有值，单位kw）
  list: [],  // 分时限额时段功率列明细表（限额方式为分时限额，必须有一条）
  oc_s_id: '',  // 关联电站id
});
const powerLimitList = ref([{
  ocl_stime: '',
  ocl_etime: '',
  ocl_power: '',
}]);  // 分时限额时段功率
/** 表单规则对象 */
const rules = reactive({
  oc_name: [{ required: true, message: "请输入规则名称", trigger: "blur", }],
  oc_type: [{ required: true, message: "请选择限额方式", trigger: "blur", }],
  oc_power: [{ required: true, message: "请输入限额功率", trigger: "blur", }],
});
const tableDatas = ref([]);  // 关联电站数据
const selectStation = ref([]); // 选中绑定电站数据
const searchStation = ref('');  // 搜索电站
const refInfo = ref(null);  // 详情对象
const editDatas = ref('');  // 当前编辑数据
const refStation = ref(null);  // 列表关联电站对象
const refStationInfo = ref(null);  // 电站详情对象
/**
 * 
 * 获得未绑定的电站列表
 * 
 * */
const getUnboundStationList = () => {
  PowerStations.getUnboundStationList({ keywords: searchStation.value, }).then((res) => {
    if (res.Code === 200) {
      tableDatas.value = res.Data.list ? res.Data.list : [];
    } else {
      ElMessage.error(res.Message);
    }
  });
}
/**
 * 
 * 新增规则按钮
 * 
 * */
const onAddBtn = () => {
  ruleForm.value = ({
    oc_name: '',  // 规则名称
    oc_type: '1',  // 限额方式1分时限额2固定限额
    oc_power: '',  // 限额功率（只有限额方式为固定限额才有值，单位kw）
    list: [],
    oc_s_id: '',  // 关联电站id
  });
  powerLimitList.value = [{
    ocl_stime: '',
    ocl_etime: '',
    ocl_power: '',
  }];
  searchStation.value = '';
  getUnboundStationList();
  isAdd.value = true;
  addDialog.value.show();
}
/**
 * 
 * 选择限额方式
 * 
 * */
const wayCahnge = () => {
  powerLimitList.value = [{
    ocl_stime: '',
    ocl_etime: '',
    ocl_power: '',
  }];
  ruleForm.value.oc_power = '';
}
/**
 * 
 * 分时功率限额设置添加按钮
 * 
 * */
const onPowerLimit = () => {
  powerLimitList.value.push({
    ocl_stime: '',
    ocl_etime: '',
    ocl_power: '',
  });
}
/**
 * 
 * 分时功率限额设置删除按钮
 * 
 * */
const onDeleteBtn = (i) => {
  if (powerLimitList.value.length == 1) {
    ElMessage.error('必须添加一条分时功率限额！');
    return false;
  }
  powerLimitList.value.splice(i, 1);
}
/**
 * 
 * 时段输入开始时间
 * 
 * */
const onTimeBlur = (i, type) => {
  const regex = /^(\d{1,2})(:(\d{2}))?$/;
  let match = '';
  if (type == 'start') {
    if (!powerLimitList.value[i].ocl_stime) return;
    match = powerLimitList.value[i].ocl_stime.match(regex);
  } else {
    if (!powerLimitList.value[i].ocl_etime) return;
    match = powerLimitList.value[i].ocl_etime.match(regex);
  }
  // const match = type == 'start' ? powerLimitList.value[i].ocl_stime.match(regex) : powerLimitList.value[i].ocl_etime.match(regex);
  if (match) {
    let hours = match[1];
    let minutes = match[3] || '00';
    hours = hours > 24 ? '24' : hours;
    if (hours == '24') {
      // 如果小时输入值是24，分钟显示00
      minutes = '00';
    } else {
      // 如果分支输入值大于60则等于00
      minutes = minutes > 60 ? '00' : minutes;
    }
    if (type == 'start') {
      powerLimitList.value[i].ocl_stime = `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
    } else {
      powerLimitList.value[i].ocl_etime = `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
    }
  } else {
    ElMessage.error('输入时间格式错误！');
  }
}
const getStationKeys = (row) => {
  return row.s_id;
}
/**
 * 
 * 选择绑定电站
 * 
 * */
const stationSelectionChange = (val) => {
  selectStation.value = val;
}
/**
 * 
 * 新增、编辑确定按钮
 * 
 * **/
const addConfirmBtn = () => {
  addForm.value.validate((valid) => {
    if (valid) {
      let arr = [];
      // 处理关联电站
      if (isAdd.value) {
        // 新增
        if (selectStation.value.length > 0) {
          selectStation.value.forEach(item => {
            arr.push(item.s_id);
          })
        }
      } else {
        // 编辑
        if (stationTableDatas.value.length > 0) {
          stationTableDatas.value.forEach(item => {
            arr.push(item.s_id);
          })
        }
      }
      let url = '', msg = '';
      let params = {
        oc_name: ruleForm.value.oc_name,
        oc_type: ruleForm.value.oc_type,
        oc_power: ruleForm.value.oc_power,
        list: [],
        oc_s_id: arr.join(','),
      };
      if (ruleForm.value.oc_type == '1') {
        // 分时限额
        for (let i = 0; i < powerLimitList.value.length; i++) {
          const range = powerLimitList.value[i];
          if (!range.ocl_stime) {
            ElMessage.error('请输入开始时段!');
            return false;
          }
          if (!range.ocl_etime) {
            ElMessage.error('请输入结束时段!');
            return false;
          }
          if (!range.ocl_power) {
            ElMessage.error('请输入充电站功率限额!');
            return false;
          }
          // 判断结束时间不能大于开始时间
          if (compareTimes(range.ocl_stime, range.ocl_etime) == 1) {
            ElMessage.error('结束时间不能大于开始时间！');
            return false;
          }
          // 判断时段是否重复
          for (let j = i + 1; j < powerLimitList.value.length; j++) {
            const otherRange = powerLimitList.value[j];
            if (isTimeOverlap(range.ocl_stime, range.ocl_etime, otherRange.ocl_stime, otherRange.ocl_etime)) {
              ElMessage.error('时段重复！');
              return false;
            }
          }
        }
        params.list = JSON.stringify(powerLimitList.value);
      }
      if (isAdd.value) {
        url = 'addOrderly';
        msg = '新增成功！';
      } else {
        url = 'editOrderly';
        msg = '编辑成功！';
        params.oc_id = editDatas.value.oc_id;
      }
      // console.log("1111111", ruleForm.value, params, url,);
      addDialog.value.isLoading = true; // 按钮加载中状态
      PowerStations[url](params).then((res) => {
        addDialog.value.isLoading = false;
        if (res.Code === 200) {
          chargeTable.value.tableLoad(); // 重新获取列表数据
          if (!isAdd.value) {
            refInfo.value.getOrderlyDetail();
          }
          ElMessage.success(msg); // 成功提示
          addDialog.value.close(); // 关闭弹框
        } else {
          ElMessage.error(res.Message);
        }
      });
    }
  });
}
/**
 * 
 * 将时分字符串转换为分钟数
 * 
 * */
const timeToMinutes = (timeStr) => {
  const parts = timeStr.split(':');
  return parts[0] * 60 + parseInt(parts[1], 10);
}
/**
 * 
 * 比较时分大小
 * 
 * */
const compareTimes = (time1, time2) => {
  const minutes1 = timeToMinutes(time1);
  const minutes2 = timeToMinutes(time2);
  return minutes1 === minutes2 ? 0 : minutes1 > minutes2 ? 1 : -1;
}
/**
 * 
 * 比较时段是否重复
 * 
 * */
const isTimeOverlap = (start1, end1, start2, end2) => {
  const start1Minutes = timeToMinutes(start1);
  const end1Minutes = timeToMinutes(end1);
  const start2Minutes = timeToMinutes(start2);
  const end2Minutes = timeToMinutes(end2);
  return start1Minutes < end2Minutes && end1Minutes > start2Minutes;
}
/**
 * 
 * 关联电站详情
 * 
 * */
const onPowerStation = (row) => {
  if (row.station_list.length > 0) {
    refStationInfo.value.openDialog(row);
  } else {
    ElMessage.error('暂无关联电站！');
  }
}
/**
 * 
 * 详情按钮
 * 
 * */
const onDetails = (row) => {
  refInfo.value.openDialog(row);
}
/**
 * 
 * 编辑页面解绑按钮
 * 
 * */
const onUnbindBtn = (row) => {
  let params = {
    oc_id: editDatas.value.oc_id,
    s_id: row.s_id,
  };
  onUnbind(params);
}
/**
 * 
 * 解绑
 * 
 * */
const onUnbind = (params) => {
  ElMessageBox.confirm(`确定要解绑该规则吗？`, '温馨提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
  }).then(() => {
    PowerStations.getOrderlyUnbind(params).then((res) => {
      if (res.Code === 200) {
        refInfo.value.getOrderlyDetail();
        chargeTable.value.tableLoad();
        ElMessage.success('解绑成功!');
        if (!isAdd.value) {
          getOrderlyDetail();
        }
      } else {
        ElMessage.error(res.Message);
      }
    });
  }).catch(() => { })
}
/**
 * 
 * 编辑规则
 * 
 * */
const onSubmit = (data) => {
  editDatas.value = data;
  isAdd.value = false;
  addDialog.value.show();
  getOrderlyDetail();
}
/**
 * 
 * 获取详情
 * 
 * */
const getOrderlyDetail = () => {
  PowerStations.getOrderlyDetail({ oc_id: editDatas.value.oc_id, }).then((res) => {
    if (res.Code === 200) {
      ruleForm.value.oc_name = res.Data.oc_name;
      ruleForm.value.oc_type = String(res.Data.oc_type);
      ruleForm.value.oc_power = res.Data.oc_power;
      stationTableDatas.value = res.Data.station_list;
      // 处理分时功率限额设置
      if (res.Data.oc_type == '1') {
        res.Data.list.forEach(item => {
          item.time = [item.ocl_stime, item.ocl_etime];
        })
        powerLimitList.value = res.Data.list;
      }
      nextTick(() => {
        stationTable.value.tableLoad();
      });
    } else {
      ElMessage.error(res.Message);
    }
  });
}
/**
 * 
 * 列表-关联电站按钮
 * 
 * */
const onAssociate = (row) => {
  refStation.value.openDialog(row);
}
/**
 * 
 * 重新获取列表数据
 * 
 * */
const onRefresh = () => {
  chargeTable.value.tableLoad();
}

onMounted(() => {
  chargeTable.value.tableLoad();
});
</script>

<style lang="scss">
.orderly-charg {
  font-family: "Source Han Sans CN";

  .squeezin {
    flex-shrink: 0;
  }

  .sign {
    color: var(--el-color-danger);
    margin-right: 4px;
  }

  .content {
    padding: 20px;
  }

  .add-dialog {
    .el-dialog__body {
      padding: 20px;
      height: 690px;
      overflow-y: auto;

      .el-row {
        border: none;
      }

      .input-white {
        .el-input__inner {
          background-color: #FFFFFF;
          border: 1px solid #CDCFD3;
        }
      }

      .input-sharing {
        .el-input__inner {
          background-color: #FFFFFF;
        }

        ;
      }

      .el-range-input {
        background-color: #FFFFFF;
      }

      .el-input-group__append {
        background-color: #F3F4F8 !important;
        border: 1px solid #CDCFD3 !important;
      }

      .power-limit {
        background-color: #F3F4F8;
        margin-top: 15px;
        padding: 20px;
      }

      .power-unit {
        background-color: #FFFFFF;
        border: 1px solid #CDCFD3;
      }

      .search-station {
        width: 80px;
        margin-left: 10px;
      }

      .add-btn {
        width: 64px;
        padding: 0;
        min-height: 28px;
        font-size: 14px;
        margin-left: 15px;
      }
    }
  }

  .el-radio__inner {
    border-radius: 1px;
  }
}
</style>